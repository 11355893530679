import React from "react"
import styled from "styled-components"
import { motion, useTransform } from "framer-motion"
import { VSection, Media } from "components/common"

import { device, gradientText, useParallax } from "utils"

// import light from "src/images/home/lightrays.svg"
import light from "src/images/home/lightrays2.svg"
import lightMobile from "src/images/home/lightraysMobile.svg"

const HomeHero = ({ hat, title, subtitle, media }) => {
  const { ref, scrollYProgress, end, isDesktop } = useParallax()

  // const orbOpacity = useTransform(
  //   scrollYProgress,

  //   [0, 0.1, end],
  //   [1, 1, 0]
  // )

  const orbY = useTransform(
    scrollYProgress,
    [0, end],

    [0, isDesktop ? 700 : 500]
  )

  return (
    <StyledHomeHero>
      <VSection
        id="hero"
        hat={hat}
        title={title}
        titleAs="h1"
        subtitle={subtitle}
      />
      {/* <motion.div
        animate={{ y: 100 }}
        transition={{ repeat: Infinity, duration: 2 }}
      > */}
      <motion.div className="orb" ref={ref} style={{ y: orbY }}>
        <Media media={media} critical />
      </motion.div>
      {/* </motion.div> */}
      <img
        className="light desktop"
        src={light}
        alt="light rays"
        loading="eager"
      />
      <img
        className="light mobile"
        src={lightMobile}
        alt="light rays"
        loading="eager"
      />
    </StyledHomeHero>
  )
}

const StyledHomeHero = styled.div`
  position: relative;
  --section-mobile-padding-top: 0;
  --section-padding-bottom: 0;
  --section-padding-top: 0;
  --section-mobile-padding-bottom: 0;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  /* padding-top: 25vh; */
  padding-top: 20vh;

  @media ${device.laptop} {
    padding-top: 0;
    align-items: center;
    justify-content: center;
  }

  #hero {
    position: relative;
    z-index: 10;
    .v_tgContainer {
      position: relative;
      --tg-text-align: center;
      --tg-max-width: 625px;
      --section-align: center;

      .hat {
        ${gradientText("var(--text_gradient)")};
      }
    }
  }

  .orb {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10;
    bottom: 0;
    bottom: -100px;

    @media ${device.laptop} {
      bottom: -200px;
    }

    @media ${device.desktopL} {
      bottom: -300px;
    }

    .gatsbyImageWrap {
      /* max-width: 300px; */
      max-width: 200px;
      margin: 0 auto;

      @media ${device.laptop} {
        /* max-width: 600px; */
        max-width: 400px;
      }
      @media ${device.desktopL} {
        /* max-width: 800px; */

        max-width: 600px;
      }

      img {
        object-fit: contain;
      }
    }
  }

  .light {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    /* height: auto; */
    /* height: 100vh; */
    /* height: calc(var(--vh, 1vh) * 105); */
    object-fit: cover;
    z-index: 5;

    &.desktop {
      display: none;
    }
    @media ${device.laptop} {
      &.desktop {
        display: block;
      }
      &.mobile {
        display: none;
      }
    }
  }
`

export default HomeHero

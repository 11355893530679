import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, VSection } from "components/common"
import Footer from "components/navigation/Footer"
import Nav from "components/navigation/Nav"
import { useContentfulSection, useMeta } from "utils"

const NotFoundPage = ({ data: { notFound } }) => {
  const {
    hero_404: { title, subtitle },
  } = useContentfulSection(notFound.sections)

  const meta = useMeta(notFound)

  return (
    <Layout hideFooter>
      <Seo {...meta} />
      <Nav />

      <Wrap>
        <VSection
          className="messageBox"
          titleAs="h1"
          align="left"
          title={title}
          subtitle={subtitle}
        />
      </Wrap>
      <Footer />
    </Layout>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 6;
  flex: 1;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--deepspace_gradient);

  .messageBox {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    .v_tgContainer {
      --tg-max-width: 800px;
      .subtitle {
        white-space: break-spaces;

        a {
          color: var(--balance);
        }
      }
    }
  }
`

export const query = graphql`
  {
    notFound: contentfulPage(pageId: { eq: "404" }) {
      ...Page
    }
  }
`

export default NotFoundPage

import React from "react"
import styled from "styled-components"

const Bubbles = () => {
  return (
    <StyledBubbles>
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
      <div className="bubble" />
    </StyledBubbles>
  )
}

const StyledBubbles = styled.div`
  /* position: relative;
  width: 100%;
  height: 100%; */
  overflow: hidden;

  .bubble {
    position: absolute;
    bottom: -100px;
    width: 20px;
    height: 20px;
    /* background: #fff; */
    background: #8cadcd;
    border-radius: 50%;
    opacity: 0.5;

    /* animation: rise 8s infinite ease-in; */
    animation: rise 8s infinite linear;
  }
  .bubble:nth-child(1) {
    width: 20px;
    height: 20px;
    left: 10%;
    animation-duration: 18s;
  }
  .bubble:nth-child(2) {
    width: 20px;
    height: 20px;
    left: 20%;
    animation-duration: 15s;
    animation-delay: 1s;
  }
  .bubble:nth-child(3) {
    width: 10px;
    height: 10px;
    left: 35%;
    animation-duration: 17s;
    animation-delay: 2s;
  }
  .bubble:nth-child(4) {
    width: 20px;
    height: 20px;
    left: 50%;
    animation-duration: 21s;
    animation-delay: 0s;
  }
  .bubble:nth-child(5) {
    width: 10px;
    height: 10px;
    left: 55%;
    animation-duration: 16s;
    animation-delay: 1s;
  }
  .bubble:nth-child(6) {
    width: 15px;
    height: 15px;
    left: 65%;
    animation-duration: 18s;
    animation-delay: 3s;
  }
  .bubble:nth-child(7) {
    width: 20px;
    height: 20px;
    left: 70%;
    animation-duration: 22s;
    animation-delay: 2s;
  }
  .bubble:nth-child(8) {
    width: 5px;
    height: 5px;
    left: 80%;
    animation-duration: 16s;
    animation-delay: 2s;
  }
  .bubble:nth-child(9) {
    width: 15px;
    height: 15px;
    left: 70%;
    animation-duration: 15s;
    animation-delay: 1s;
  }
  .bubble:nth-child(10) {
    width: 10px;
    height: 10px;
    left: 25%;
    animation-duration: 20s;
    animation-delay: 4s;
  }
  @keyframes rise {
    0% {
      bottom: -100px;
      transform: translateX(0);
      opacity: 0.02;
      filter: blur(0.3px);
    }
    50% {
      transform: translate(100px);
      opacity: 0.2;
      filter: blur(1.3px);
    }
    100% {
      bottom: 100vh;
      transform: translateX(-200px);
      opacity: 0.5;
      filter: blur(0.3px);
    }
  }
`

export default React.memo(Bubbles)

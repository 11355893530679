import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, VSection } from "components/common"
import Footer from "components/navigation/Footer"
import Nav from "components/navigation/Nav"

import { richTextBody, useContentfulSection, useMeta } from "utils"

const PPPage = ({ data: { pp } }) => {
  const {
    pp_content: { title, subtitle },
  } = useContentfulSection(pp.sections)

  const meta = useMeta(pp)

  return (
    <Layout hideFooter>
      <Seo {...meta} />

      <Wrap>
        <Nav />
        <VSection
          className="pp_content"
          titleAs="h1"
          align="left"
          title={title}
          subtitle={subtitle}
        />
      </Wrap>
      <Footer />
    </Layout>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 6;
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-bottom: 300px;
  --section-padding-bottom: 350px;
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-background-color: none;
  /* --nav-background-color: #1b3965; */
  background: var(--deepspace_gradient);

  .pp_content {
    ${richTextBody};

    .title {
      margin-bottom: var(--tg-grid-gap);
    }

    strong {
      color: var(--white);
    }
  }
`

export const query = graphql`
  {
    pp: contentfulPage(pageId: { eq: "privacy_policy" }) {
      ...Page
    }
  }
`

export default PPPage

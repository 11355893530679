import React, { useState } from "react"
import styled from "styled-components"

import newLettersSignup from "src/utils/api/hubspotNewletterSignup"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup" // for everything
import { One, Input } from "components/inputs"

const FORM_ID = process.env.GATSBY_HUBSPOT_FORM_ID

const isProd =
  process.env.NODE_ENV === "production" &&
  process.env.GATSBY_ENV === "production"

// const isProd = true

const defaultValues = {
  email: "",
  name: "", // honeypot
}

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("valid email is required")
    .required("email is required"),
})

const ContactForm = () => {
  const [formMsg, setFormMsg] = useState()

  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = async (values) => {
    if (!values.name && isProd) {
      try {
        const { status, data } = await newLettersSignup.post(`/${FORM_ID}`, {
          submittedAt: new Date(),
          // skipValidation: true,
          fields: [
            {
              name: "email",
              value: values.email,
            },
          ],
        })

        if (status === 200) {
          reset(defaultValues)
        } else {
          reset(values)
        }

        setFormMsg(data.inlineMessage) // set msg reguardless
      } catch (err) {
        if (err.response && err.response.data) {
          const invalidEmail =
            err.response.data.errors[0].errorType === "INVALID_EMAIL"

          setError("email", {
            type: "manual",
            message: invalidEmail
              ? "Email is invalid, please try again."
              : "Sorry an unknown error occured, please try again.",
            shouldFocus: true,
          })
        } else {
          setError("email", {
            type: "manual",
            message: "Sorry an unknown error occured, please try again.",
            shouldFocus: true,
          })
        }
      }
    } else {
      reset()
      setError("email", {
        type: "manual",
        message: "Not Submitted, Not Production",
        shouldFocus: true,
      })
    }
  }
  return (
    <StyledContactForm
      id="ContactForm"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {formMsg ? (
        <FormMsg>{formMsg}</FormMsg>
      ) : (
        <>
          <Input
            errors={errors}
            className="name"
            id="name"
            type="text"
            name="name"
            placeholder="name"
            register={register}
          />

          <One
            className="email"
            type="email"
            name="email"
            placeholder="Enter your email address"
            errors={errors}
            register={register}
            isSubmitting={isSubmitting}
            text="SIGN UP"
            required
          />
        </>
      )}
    </StyledContactForm>
  )
}

const StyledContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .name-wrap {
    position: absolute;
    z-index: -9999;
    left: 0;
    bottom: 0;
    visibility: hidden;
  }
`

const FormMsg = styled.p`
  text-align: center;
  padding: var(--sp-16);
  max-width: 540px;
`

export default ContactForm

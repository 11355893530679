import React from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { Button, Link } from "components/common"
import { device } from "utils"

import Logo from "src/images/nav/deepwatersElementsLogo.inline.svg"

const Nav = () => {
  return (
    <StyledNav>
      <Link to="/">
        <Logo className="logo" loading="eager" />
      </Link>
      <AnchorLink to="/#contact">
        <Button>CONTACT US</Button>
      </AnchorLink>
    </StyledNav>
  )
}

const StyledNav = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
  padding: var(--sp-24);
  background: var(--nav-background-color);

  @media ${device.laptop} {
    padding: var(--sp-48);
  }

  .logo {
    max-width: 130px;

    @media ${device.laptop} {
      max-width: 100%;
    }
  }
`

export default Nav

import axios from "axios"

const API = process.env.GATSBY_HUBSPOT_API
const PORTAL_ID = process.env.GATSBY_HUBSPOT_PORTAL_ID

// https://legacydocs.hubspot.com/docs/methods/forms/submit_form

const baseURL = `${API}/${PORTAL_ID}`

// const createHubSpotBody = (values) => ({
//   submittedAt: Date.now(),
//   skipValidation: true,
//   fields: map(values, (value, name) => ({ name, value })),
// })

const instance = axios.create({
  baseURL,
  // body: {
  //   context: {
  //     hutk: ":hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
  //     pageUri: "www.example.com/page",
  //     pageName: "Example page",
  //   },
  //   legalConsentOptions: {
  //     // Include this object when GDPR options are enabled
  //     consent: {
  //       consentToProcess: true,
  //       text: "I agree to allow Example Company to store and process my personal data.",
  //       communications: [
  //         {
  //           value: true,
  //           subscriptionTypeId: 999,
  //           text: "I agree to receive marketing communications from Example Company.",
  //         },
  //       ],
  //     },
  //   },
  // },
  //   headers: { "content-type": "application/x-www-form-urlencoded" },
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json, application/xml, text/plain, text/html, *.*",
  //   },
})

export default instance

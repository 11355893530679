import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { useInView, device } from "utils"

const Video = ({ desktop, mobile, muted, autoPlay, loop }) => {
  const { ref, isVisible } = useInView()

  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setPlaying(true)
    } else {
      setPlaying(false)
    }
  }, [isVisible])

  return (
    <VideoWrap className="videoWrap" ref={ref}>
      <ReactPlayer
        className="video desktop"
        width="100%"
        height="100%"
        playing={playing}
        url={desktop}
        muted={muted}
        autoPlay={autoPlay}
        loop={loop}
      />
      <ReactPlayer
        className="video mobile"
        width="100%"
        height="100%"
        playing={playing}
        url={mobile}
        muted={muted}
        autoPlay={autoPlay}
        loop={loop}
      />
    </VideoWrap>
  )
}

const VideoWrap = styled.div`
  height: 100%;
  width: 100%;

  .desktop {
    display: none;
  }
  @media ${device.laptop} {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
`

Video.defaultProps = {
  src: "",
  muted: true,
  autoPlay: false,
  loop: true,
}

export default Video

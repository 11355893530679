import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import { useViewportScroll, motion, useTransform } from "framer-motion"

import Clock from "./Clock"

const depths = [
  "1,000",
  "1,500",
  "2,000",
  "2,500",
  "3,000",
  "3,500",
  "4,000",
  "4,500",
  "5,000",
  "5,500",
  "6,000",
  "6,500",
  "7,000",
  "7,500",
  "8,000",
  "8,500",
  "9,000",
  "9,500",
  "10,000",
  "10,500",
  "11,000",
  "11,035",
]

const Stats = () => {
  const { scrollYProgress } = useViewportScroll()
  const isDesktop =
    typeof window !== "undefined"
      ? window.matchMedia("(min-width: 1024px)").matches
      : { matches: false }

  const y = useTransform(scrollYProgress, [0, 1], ["0%", "-97.3%"])
  const opacity = useTransform(scrollYProgress, [0, 0.85, 0.9], [1, 1, 0])

  const renderDepth = () => (
    <motion.ul className="depthsWrap" style={{ y }}>
      {map(depths, (d) => (
        <li key={d} className="depth">
          {d}
        </li>
      ))}
    </motion.ul>
  )

  return (
    <StyledStats style={{ opacity: isDesktop ? 1 : opacity }}>
      <span className="stat time">
        <Clock />
      </span>
      <span className="stat loc">11° 20' 59.99" N</span>
      <span className="stat loc">142° 11' 60.00" E</span>
      <div className="stat depths">{renderDepth()}</div>
    </StyledStats>
  )
}

const StyledStats = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--sp-8);

  .stat {
    font-family: Voyager;

    line-height: 1;
    opacity: 0.5;
    font-size: 0.875rem;
    letter-spacing: 0.08em;

    &.depths {
      display: inline-block;
      position: relative;
      height: 12px;
      overflow: hidden;

      &:after {
        content: "m";
        position: absolute;
        /* left: 50px; */
        left: 55px;
        bottom: 0px;
        font-size: 0.875rem;
        letter-spacing: 0.08em;
      }

      .depthsWrap {
        display: grid;
        grid-template-columns: 1fr;
        /* grid-gap: var(--sp-16); */
        /* grid-gap: 12px; */
        grid-gap: 8px;

        .depth {
          font-size: 0.875rem;
          letter-spacing: 0.08em;
        }
      }
    }
  }
`

export default React.memo(Stats)

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Error from "./Error"
import { device } from "utils"
import { Button } from "components/common"
import Input from "./Input"

// https://codesandbox.io/s/react-hook-form-controller-079xx?file=/src/index.js:833-1061

/**
 * @description Input Component
 * @typedef {{
 * name: String
 * type: String
 * className: String
 * placeholder: String
 * required: Boolean
 * register: Function
 * errors: {}
 *
 * Props
 * @type React.FunctionComponent<Props>
 *
 * @example
 * <Input name="test" />
 */

const One = ({
  name,
  register,
  className,
  type,
  label,
  placeholder,
  required,
  errors,
  isSubmitting,
  text,
  ...other
}) => {
  return (
    <OneWrap>
      <div className="inputContainer">
        <Input
          errors={errors}
          className={className}
          type={type}
          name={name}
          placeholder={placeholder}
          register={register}
        />
        <Button type="submit" className="submit" disabled={isSubmitting}>
          {isSubmitting ? "please wait..." : text}
        </Button>
      </div>
      {/* <div className="msg">
        <Error errors={errors} name={name} />
      </div> */}
    </OneWrap>
  )
}

const OneWrap = styled.div`
  max-width: 400px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--sp-24);

  .inputContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-16);

    @media ${device.laptop} {
      grid-template-columns: 1fr auto;
    }
  }

  .msg {
    margin-left: var(--sp-8);
  }
`

One.defaultProps = {
  name: "",
  className: "",
  type: "text",
  placeholder: "placeholder",
  text: "placeholder text",
  required: false,
}

One.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
}

export default One

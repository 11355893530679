import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import "src/styles/reset.css"
import "src/styles/fonts/font-family.css"
import "src/styles/theme.css"
import "src/styles/layout.css"
import "src/styles/typography.css"

import Footer from "components/navigation/Footer"

import ErrorBoundary from "./ErrorBoundary"

import { useSetVh } from "utils"

const Layout = ({ children, hideFooter }) => {
  useSetVh()

  return (
    <Container>
      <ErrorBoundary>
        <main>{children}</main>
      </ErrorBoundary>
      {!hideFooter && <Footer />}

      <Overlay className="overlay full-height" />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  background: var(--deepspace_gradient);
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  background: var(--deepspace-overlay);
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

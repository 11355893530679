import React from "react"
import styled from "styled-components"

import { VSection, ValuePropElement, Grid } from "components/common"

import { device } from "utils"

const HomeElements = ({ valueProps }) => {
  const renderGrid = () => (
    <Grid list={valueProps} component={(vp) => <ValuePropElement {...vp} />} />
  )

  return (
    <StyledHomeElements>
      <VSection id="elements" grid={renderGrid} />
    </StyledHomeElements>
  )
}

const StyledHomeElements = styled.div`
  --section-align: center;
  #elements {
    position: relative;
    z-index: 6;
    .v_gridContainer {
      --grid-col: 1;
      --grid-gap: 200px;
      --grid-mobile-gap: 80px;
      --grid-col-max: 640px;

      @media ${device.laptop} {
        --grid-mobile-gap: 300px;
      }

      .vpElement {
        &:nth-child(1) {
          --tg-hat-color: var(--swap);
        }
        &:nth-child(2) {
          --tg-hat-color: var(--balance);
        }
        &:nth-child(3) {
          --tg-hat-color: var(--vault);
        }
        &:nth-child(4) {
          --tg-hat-color: var(--note);
        }
        &:nth-child(5) {
          --tg-hat-color: var(--oracle);
        }
        &:nth-child(6) {
          --tg-hat-color: var(--token);
        }
      }
    }
  }
`

export default HomeElements

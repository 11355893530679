import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { Media, Link } from "components/common"

import { device } from "utils"

const Footer = () => {
  const { footer } = useStaticQuery(query)

  return (
    <StyledFooter>
      <Media className="oceanFloor" media={footer.backgroundImage} />

      <ul className="links">
        <li>
          <a
            href="mailto:yields@deepwaters.xyz"
            target="_blank"
            rel="noreferrer"
          >
            yields@deepwaters.xyz
          </a>
        </li>
        <li>2021 VatnForn, LLC</li>
        <li>Not an offer | No representation or warranty</li>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
      </ul>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 31;

  @media ${device.desktopL} {
    position: absolute;
  }

  .oceanFloor {
    width: 100%;
  }

  .links {
    background: #060b13;

    text-align: center;
    display: grid;
    grid-gap: var(--sp-8);
    padding: var(--sp-24) var(--sp-48);

    li,
    a {
      color: var(--body-font-color);
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 18px;
      letter-spacing: 0.01em;
    }
  }
`

export default Footer

const query = graphql`
  {
    footer: contentfulSection(sectionId: { eq: "footer" }) {
      ...Section
    }
  }
`

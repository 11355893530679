import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { getGPUTier } from "detect-gpu"

import hud from "src/images/hud/HUD_desktop.svg"

import Bubbles from "components/animations/Bubbles"
import Nav from "components/navigation/Nav"

import DBar from "./DBar"

import Stats from "./Stats"

import { device } from "utils"

// overlay =  5
const bubbles_z = 5
// light: 5
// parralax = 6
// elements 6
// orb = 10
//hero = 10
// swap - 11
const stas_z_mobile = 20
const stas_z = 32
const hud_z = 20
// nav = 20
// contact = 30
// footer = 31

const PrimaryHud = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    // detect gpu and hide permance items from low teir gpus
    const detect = async () => {
      const { tier, gpu } = await getGPUTier()

      if (tier >= 2 || gpu.match(/apple|Apple|M1|m1/)) {
        setShow(true)
      }
    }

    detect()
  }, [])

  return (
    <>
      <Nav />

      <Hud src={hud} className="hud desktop" alt="hud" loading="eager" />

      <StatsWrap className="statsWrap">
        <Stats />
      </StatsWrap>

      {show && (
        <DBarWrap>
          <DBar />
        </DBarWrap>
      )}
      {show && (
        <BubbleContainer className="bubbleContainer">
          <Bubbles show={show} />
        </BubbleContainer>
      )}
    </>
  )
}

const Hud = styled.img`
  position: fixed;
  opacity: 0.4;
  width: 100%;
  height: 100vh;

  object-fit: contain;
  z-index: ${hud_z};

  &.desktop {
    display: none;
  }

  @media ${device.laptop} {
    left: 50%;
    transform: translateX(-50%);
    max-width: 1300px;
    &.mobile {
      display: none;
    }
    &.desktop {
      display: block;
    }
  }
`

const DBarWrap = styled.div`
  position: fixed;
  bottom: var(--sp-24);
  right: var(--sp-24);
  z-index: ${stas_z_mobile};
  @media ${device.laptop} {
    bottom: var(--sp-48);
    right: var(--sp-48);
    z-index: ${stas_z};
  }
`

const StatsWrap = styled.div`
  position: fixed;
  bottom: var(--sp-24);
  left: var(--sp-24);
  z-index: ${stas_z_mobile};
  @media ${device.laptop} {
    bottom: var(--sp-48);
    left: var(--sp-48);
    z-index: ${stas_z};
  }
`
const BubbleContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${bubbles_z};
`

export default PrimaryHud

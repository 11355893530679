import React, { useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import map from "lodash/map"

import { device } from "utils"

/**
 * Renders a <Grid /> component
 * @param {object} props Component Props
 * @param {[{id: string | number}]} props.list
 * @param {React.Component} props.component
 * @example
 * <Grid list=['a] component={() => <div></div>} />
 */
const Grid = ({ list, component, children, ...other }) => {
  const items = useCallback(
    () => renderList(list, component),
    [list, component]
  )

  return <StyledGrid {...other}>{list.length ? items() : children}</StyledGrid>
}

const renderList = (list, Component) => {
  return map(list, (props) => {
    const { id } = props
    return <Component key={id} {...props} />
  })
}

const StyledGrid = styled.ul`
  display: grid;
  grid-gap: var(--grid-mobile-gap);
  justify-content: var(--grid-mobile-align);

  grid-template-columns: repeat(
    var(--grid-mobile-col),
    minmax(var(--grid-mobile-col-min), var(--grid-mobile-col-max))
  );

  @media ${device.laptop} {
    justify-content: var(--grid-align);
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(
      var(--grid-col),
      minmax(var(--grid-col-min), var(--grid-col-max))
    );
  }
`

Grid.defaultProps = {
  list: [],
  children: null,
  component: <span />,
}
Grid.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired
  ),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

export default Grid

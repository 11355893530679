import { css } from "styled-components"

import { device } from "utils"

const richTextBody = () => css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  a {
    color: var(--primary-font-color);
  }

  h2,
  h3,
  h4 {
    margin-bottom: var(--sp-24);
  }

  p {
    margin-bottom: var(--sp-16);
    white-space: break-spaces;
    word-break: break-word;
    overflow-wrap: break-word;

    @media ${device.laptop} {
      margin-bottom: var(--sp-32);
    }
  }

  a {
    font-weight: 600;
    white-space: break-spaces;
    word-break: break-all;
    overflow-wrap: break-all;
  }

  blockquote {
    @media ${device.laptop} {
      margin-bottom: var(--sp-32);
      margin-top: 0;
    }
    p {
      font-family: var(--title-font-family);
      color: #6270d2;
      max-width: 580px;

      text-align: left;
      /* 20px */
      font-size: 1.25rem;
      line-height: 32px;
    }
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 30px;
    margin-bottom: var(--sp-16);

    @media ${device.laptop} {
      margin-bottom: var(--sp-32);
    }
    p {
      margin-bottom: 0;
    }
  }

  ol {
    list-style: decimal;
  }

  img {
    width: 100%;
    border-radius: var(--card-radius);
  }

  i {
    font-style: italic;
  }
  strong {
    font-weight: 700;
  }

  /* remove the grid-gap spacing... */
  .tgWrap {
    display: block;
  }
`

export default richTextBody

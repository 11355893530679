import { css } from "styled-components"

import gradientBorder from "utils/styles/gradientBorder"

export default css`
  position: relative;
  label {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    margin-bottom: var(--sp-8);
    display: inline-block;
  }

  input {
    font-size: 0.875rem;
    width: 100%;
    padding: var(--sp-16) var(--sp-24);
    border-radius: 45px;
    font-family: var(--primary-font-family);
    font-weight: normal;
    color: var(--white);
    outline: none !important;
    box-shadow: none;
    letter-spacing: 0.01em;

    /* background: var(--button-background); */
    background: var(--button-background);
    border: 1px solid #445c7f;

    /* ${gradientBorder({
      background:
        "180deg, var(--button-background) 0%, var(--button-background) 100%",
      gradient: "var(--border-gradient)",
    })}; */

    &::placeholder {
      letter-spacing: 0.01em;
      color: #c1cff1;
    }
  }

  /* input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    background-color: var(--button-background) !important;
    color: var(--white) !important;
    -webkit-box-shadow: 0 0 0 1000px var(--button-background) inset !important;
    -webkit-text-fill-color: var(--white) !important;
  } */
`

import React from "react"
import styled from "styled-components"

import { VSection } from "components/common"

import { gradientText, device } from "utils"

import ContactForm from "components/forms/ContactForm"

const HomeContact = ({ hat, title, media }) => {
  const renderForm = () => {
    return <ContactForm />
  }

  return (
    <StyledHomeContact id="contact">
      <VSection
        id="contact"
        hat={hat}
        title={title}
        titleAs="h1"
        media={media}
        custom={renderForm}
      />
    </StyledHomeContact>
  )
}

const StyledHomeContact = styled.div`
  --section-mobile-padding-top: 0;
  --section-padding-bottom: 0;
  --section-padding-top: 0;
  --section-mobile-padding-bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 30;
  height: calc(var(--vh, 1vh) * 120);

  @media ${device.laptop} {
    height: calc(var(--vh, 1vh) * 100);
  }

  #contact {
    .v_tgContainer {
      position: relative;
      z-index: 3;
      --tg-text-align: center;
      --tg-max-width: 630px;
      --section-align: center;

      .hat {
        ${gradientText("var(--text_gradient)")};
      }
    }

    .v_customContainer {
      --section-order: 2;
      --section-mobile-order: 2;
      margin-top: var(--sp-40);
    }
    .v_mediaContainer {
      text-align: center;
      --section-max-width: 600px;
      --section-align: center;
      --section-order: 3;
      --section-mobile-order: 3;
    }
  }
`

export default HomeContact

import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import map from "lodash/map"
import { useTransform, motion } from "framer-motion"

import { useParallax, device } from "src/utils"

import Balance from "src/images/transition/balance_parralax_icon.inline.svg"
import Note from "src/images/transition/note_parralax_icon.inline.svg"
import Oracle from "src/images/transition/oracle_parralax_icon.inline.svg"
import Swap from "src/images/transition/swap_parralax_icon.inline.svg"
import Token from "src/images/transition/token_parralax_icon.inline.svg"
import Vault from "src/images/transition/vault_parralax_icon.inline.svg"

const items = [
  { className: "balance", icon: Balance, yOffset: -300, r: 270 },
  { className: "swap", icon: Swap, yOffset: -600, r: 370 },
  { className: "vault", icon: Vault, yOffset: -500, r: 570 }, // med
  { className: "note", icon: Note, yOffset: -400, r: 100 }, // sm
  { className: "oracle", icon: Oracle, yOffset: -200, r: 150 }, // sm
  { className: "token", icon: Token, yOffset: -250, r: 170 }, // sm
]

const HomeTransition = () => {
  return (
    <StyledHomeTransition>
      {map(items, (item) => (
        <PItem key={item.className} {...item} />
      ))}
    </StyledHomeTransition>
  )
}

const PItem = ({ className, icon: Icon, yOffset, r }) => {
  const { ref, scrollY, start_px, end_px, isDesktop } = useParallax()

  const y = useTransform(scrollY, [start_px, end_px], [0, yOffset])

  const rotate = useTransform(scrollY, [start_px, end_px], [0, r])

  return (
    <motion.div
      className={classNames("parallax", className)}
      ref={ref}
      style={{ y, rotate }}
    >
      <Icon />
    </motion.div>
  )
}

const StyledHomeTransition = styled.div`
  position: relative;
  z-index: 5;
  height: calc(var(--vh, 1vh) * 90);

  .parallax {
    position: absolute;
    z-index: 5;
    /* display: none; */

    display: inline-block;

    svg {
      max-width: 100px;
      max-height: 100px;

      @media ${device.laptop} {
        max-height: 100%;
        max-width: 100%;
      }
    }

    &.balance {
      /* border: 1px solid white; */

      bottom: 20vh;
      right: 7vw;
      @media ${device.laptop} {
        right: 5vw;
      }
    }

    &.vault {
      /* border: 3px solid red; */
      bottom: -20vh;
      right: 50vw;

      @media ${device.laptop} {
        bottom: -10vh;
        right: 30vw;
      }
    }

    &.swap {
      /* border: 1px solid yellow; */
      z-index: 11;
      bottom: 0;
      left: 20vw;

      @media ${device.laptop} {
        left: 20vw;
      }
    }

    &.oracle {
      /* border: 1px solid blue; */
      /* top: 20vh; */
      bottom: 20vh;
      right: 25vw;
      @media ${device.laptop} {
        right: 25vw;
      }
    }
    &.note {
      /* border: 1px solid green; */
      bottom: 10vh;
      left: 6vw;
      @media ${device.laptop} {
        left: 10vw;
      }
    }
    &.token {
      /* border: 1px solid black; */
      bottom: -10vh;
      left: 30vw;
      @media ${device.laptop} {
        left: 40vw;
      }
    }
  }
`

export default React.memo(HomeTransition)

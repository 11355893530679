import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { useViewportScroll, motion, useTransform } from "framer-motion"

import needle from "src/images/hud/needle.svg"
import ticks from "src/images/hud/ticks.svg"

const DBar = () => {
  const [dbar, setDBar] = useState(1000)
  const isDesktop =
    typeof window !== "undefined"
      ? window.matchMedia("(min-width: 1024px)").matches
      : { matches: false }
  const { scrollYProgress } = useViewportScroll()

  useEffect(() => {
    scrollYProgress.onChange((latest) => {
      setDBar(Math.floor(latest * 10035 + 1000))
    })
  }, [])

  const rotate = useTransform(scrollYProgress, [0, 1], [0, 260])
  const opacity = useTransform(scrollYProgress, [0, 0.85, 0.9], [1, 1, 0])

  return (
    <StyledDBAR style={{ opacity: isDesktop ? 1 : opacity }}>
      <div className="needle-tick">
        <motion.img
          src={needle}
          alt="needle"
          className="needle"
          style={{ rotate }}
        />
      </div>
      <img src={ticks} alt="ticks" className="ticks" />

      <div className="text">
        <div className="dbar">{dbar}</div>
        <span className="legend">dbar</span>
      </div>
    </StyledDBAR>
  )
}

const StyledDBAR = styled(motion.div)`
  position: relative;

  .needle-tick {
    position: absolute;
    top: 0;
    left: 0;

    @keyframes tick {
      0% {
        transform: rotate(1deg);
      }
      25% {
        transform: rotate(-1deg);
      }
      50% {
        transform: rotate(1deg);
      }
      75% {
        transform: rotate(-2deg);
      }
      100% {
        transform: rotate(1deg);
      }
    }

    animation: tick 0.5s infinite;
    animation-timing-function: ease-in-out;
  }

  .text {
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    text-align: center;
    font-family: "Voyager";
    margin: 0 auto;
    opacity: 0.4;
    font-size: 0.875rem;
    letter-spacing: 0.08em;

    .dbarScroll {
      display: inline-block;
      position: relative;
      height: 16px;
      overflow: hidden;

      .dbar {
        margin-bottom: 4px;
      }
    }
  }
`

export default React.memo(DBar)

import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// import Arrow from "images/common/arrow.inline.svg"

import { useTracking, gradientBorder, device } from "utils"

/**
 * Button
 * @param {object} props
 * @param {boolean} props.small
 * @param {('default')} props.theme
 * @param {object} props.track
 * @param {string} props.track.action
 * @param {string} props.track.category
 * @param {string} props.track.label
 *
 * @example
 * <Button theme="black" small>Test</Button>
 */

const Button = ({ children, track, onClick, disabled, ...other }) => {
  const [trackClick] = useTracking(track)

  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick()
    }
    if (!disabled) {
      trackClick()
    }
  }

  return (
    <StyledButton onClick={handleClick} {...other}>
      <span className="text">{children}</span>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: var(--button-font-family);
  letter-spacing: 0.16em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;

  border-radius: var(--button-radius);
  color: var(--button-color);
  box-shadow: var(--button-shadow);
  /* padding: var(--button-padding); */
  font-size: var(--button-mobile-font-size);

  @media ${device.laptop} {
    font-size: var(--button-font-size);
  }

  transition: 0.1s all ease-in-out;
  transition-property: opacity, background;

  position: relative;
  .text {
    width: 100%;
    display: block;
    position: relative;
    z-index: 3;
    /* background: var(--button-background); */
    border-radius: var(--button-radius);
    padding: var(--button-mobile-padding);

    ${gradientBorder({
      background:
        "180deg, var(--button-background) 0%, var(--button-background) 100%",
      gradient: "180deg, #609BF2 0%, #7560F2 34%, #A753AF 68%, #DA6D92 100%",
    })};

    @media ${device.laptop} {
      padding: var(--button-padding);
    }
  }

  &:hover {
    opacity: 0.8;

    .button_arrow {
      transform: translateX(var(--sp-8));
    }
  }

  /* .button_arrow {
    margin-left: var(--sp-12);
    transition: transform 0.2s ease-in-out;
    will-change: transform;
    path {
      fill: ${({ theme }) => (theme === "yellow" ? "#001127" : "")};
    }
  } */
`

Button.propTypes = {
  track: PropTypes.shape({
    action: PropTypes.string,
    category: PropTypes.string,
    label: PropTypes.string.isRequired,
  }).isRequired,
  small: PropTypes.bool,
  theme: PropTypes.oneOf(["default", "alt"]).isRequired,
}

Button.defaultProps = {
  theme: "default",
  small: false,
  track: {
    action: "select_content",
    category: "CTA",
    label: "",
  },
}

export default Button

import React from "react"
import { graphql } from "gatsby"
// import styled from "styled-components"

import { useContentfulSection, useMeta } from "utils"
import { Layout, Seo } from "components/common"

import {
  HomeHero,
  HomeElements,
  HomeTransition,
  HomeContact,
} from "src/sections/home"
import Hud from "components/hud/PrimaryHud"

const HomePage = ({ data: { home } }) => {
  const meta = useMeta(home)
  const { home_hero, home_elements, home_contact } = useContentfulSection(
    home.sections
  )

  return (
    <Layout>
      <Seo {...meta} />

      <Hud />

      <HomeHero {...home_hero} />
      <HomeTransition />
      <HomeElements {...home_elements} />
      <HomeContact {...home_contact} />
    </Layout>
  )
}

export const query = graphql`
  {
    home: contentfulPage(pageId: { eq: "home" }) {
      ...Page
    }
  }
`

export default HomePage

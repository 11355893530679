import { useMemo } from "react"
import { useTransform, useViewportScroll } from "framer-motion"

import { useScrollProgress } from "utils"

/**
 * useParallax
 * @param {object} props
 * @param {number} props.offset
 * @param {array} props.output
 *
 * @returns Options
 * @example
 * const {style} = useParallax({output: [0,1]})
 */

// https://www.framer.com/docs/motionvalue/##usetransform

// https://dixonandmoe.com/rellax/ --- just slow scrolling....

const Parallax = (props) => {
  const {
    offsetStart = 0, // top of elem to top of screen
    offsetEnd = 0, // bottom of elem to top of screen
    output = [0, 1],
    mobileOutput = [0, 1],
  } = props || { output: [0, 1], mobileOutput: [0, 1] }

  const { scrollYProgress, scrollY } = useViewportScroll()

  const media = useMemo(() => {
    let isDesktop = { matches: false }
    if (typeof window !== "undefined") {
      isDesktop = window.matchMedia("(min-width: 1024px)")
    }

    return {
      output: isDesktop ? output : mobileOutput ? mobileOutput : output,
      isDesktop,
    }
  }, [output, mobileOutput])

  const { ref, start, end, start_px, end_px } = useScrollProgress({
    offsetStart,
    offsetEnd,
  })

  const style = useTransform(scrollYProgress, [start, end], media.output)

  return {
    isDesktop: media.isDesktop,
    start,
    start_px,
    end,
    end_px,
    scrollYProgress,
    scrollY,
    style,
    ref,
  }
}

export default Parallax

// output and style is optional for more complex examples like below or can be used as a single

// import { motion, useTransform } from "framer-motion"

// const { ref, scrollYProgress, start, end, isDesktop } = useParallax({
//   offsetStart: 200,
//   offsetEnd: 100,
// })

// const sunY = useTransform(
//   scrollYProgress,
//   [start, end],
//   [isDesktop ? 200 : 100, 0]
// )

// const sunShadow = useTransform(
//   scrollYProgress,
//   [start, end],
//   [
//     "0px 0px 112px 26px rgba(226, 154, 13, 0.4),inset 8px 8px 32px rgba(228, 149, 22, 0.64)",
//     "0px 0px 237px rgba(226, 154, 13, 1), inset 8px 8px 32px rgba(228, 149, 22, 0.64)",
//   ]
// )

/* <div className="sunWrap" ref={ref}>
<Sun as={motion.div} style={{ y: sunY, boxShadow: sunShadow }} />
</div> */
